'use client';

import Script from 'next/script';
import * as snippet from '@segment/snippet';
import { ANALYTICS_ENABLED, ANALYTICS_KEY } from '@/app/utils/constants';
import {
  getRetailerFaviconUrl,
  getRetailerMappingByHostname,
} from '@/app/services/retailerService';

const renderSegmentSnippet = () => {
  const options = {
    apiKey: ANALYTICS_KEY,
    page: true,
  };
  return process.env.NODE_ENV === 'development' ? snippet.max(options) : snippet.min(options);
};

export default function MetaTags() {
  const windowEl = typeof window !== "undefined" ? window : null;
  const srcPartnerId =
    windowEl && getRetailerMappingByHostname(windowEl.location.hostname).srcPartnerId;

  return (
    <head>
      <link rel="shortcut icon" href={`${getRetailerFaviconUrl(srcPartnerId ?? 0)}`} />
      <title>{'My Projects'}</title>
      <meta name="description" content={'My Projects Description'} key="description" />
      {ANALYTICS_ENABLED && (
        <Script
          id={'segment-snippet'}
          dangerouslySetInnerHTML={{ __html: renderSegmentSnippet() }}
        />
      )}
    </head>
  );
}
