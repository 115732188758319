'use client';
import { AppContext } from '@/app/appProvider';
import { ByPhoneOutline } from '@/app/components/icons/CustomIconPack';
import CustomLink from '@/app/components/navigation/CustomLink';
import { getFooterLink } from '@/app/config/footer/footerLinks';
import { getRetailerConfig } from '@/app/services/retailerService';
import { TextDecorationEnum } from '@/app/utils/constants';
import { formatPhoneNumberWithParens } from '@/app/utils/helpers';
import cn from 'classnames';
import { useLocale, useTranslations } from 'next-intl';
import { useContext } from 'react';
import { Col, Container, Row } from 'reactstrap';
import styles from './Footer.module.scss';

type Props = {
  dark?: boolean;
};

export default function Footer({ dark }: Props) {
  const appContext = useContext(AppContext);
  const locale = useLocale();
  const retailerConfig = getRetailerConfig(appContext.srcPartnerId);
  const t = useTranslations('components.footer');
  const classNames = cn(styles.mainFooter, dark ? styles.mainFooterDark : '');
  const disclaimer = t.rich('disclaimer', { p: (chunk) => <p>{chunk}</p> });
  console.log(retailerConfig);

  return (
    <footer className={classNames}>
      <Container>
        <Row className="mt-4">
          <Col sm={16} lg={8}>
            <div>
              <div className="small fw-bolder text-uppercase mb-1">{t('helpfulLinks')}</div>
              <ul className={styles.linksList}>
                {/* TODO to be added later */}
                {/*<li>
                  <CustomLink textDecoration={TextDecorationEnum.none} href={'/'}>
                    {t('siteMap')}
                  </CustomLink>
                </li>*/}
                <li>
                  <CustomLink
                    textDecoration={TextDecorationEnum.none}
                    href="https://get.adobe.com/reader/"
                  >
                    {t('adobeReader')}
                  </CustomLink>
                </li>
                {retailerConfig?.accessibilityStatement && (
                  <li>
                    <CustomLink
                      textDecoration={TextDecorationEnum.none}
                      href={retailerConfig.accessibilityStatement}
                    >
                      {t('accessibilityStatement')}
                    </CustomLink>
                  </li>
                )}
                {retailerConfig?.footerLinks && (
                  <>
                    {getFooterLink('policy', locale, retailerConfig.footerLinks) && (
                      <li>
                        <CustomLink
                          textDecoration={TextDecorationEnum.none}
                          href={getFooterLink('policy', locale, retailerConfig.footerLinks)}
                        >
                          {t('privacyAndLicensing')}
                        </CustomLink>
                      </li>
                    )}
                    {getFooterLink('terms', locale, retailerConfig.footerLinks) && (
                      <li>
                        <CustomLink
                          textDecoration={TextDecorationEnum.none}
                          href={getFooterLink('terms', locale, retailerConfig.footerLinks)}
                        >
                          {t('termsAndConditions')}
                        </CustomLink>
                      </li>
                    )}
                    {getFooterLink('caPolicy', locale, retailerConfig.footerLinks) && (
                      <li>
                        <CustomLink
                          textDecoration={TextDecorationEnum.none}
                          href={getFooterLink('caPolicy', locale, retailerConfig.footerLinks)}
                        >
                          {t('californiaPrivacyPolicy')}
                        </CustomLink>
                      </li>
                    )}
                  </>
                )}
              </ul>
            </div>
          </Col>
          {retailerConfig?.phoneNumber && (
            <Col sm={16} lg={8} className={styles.mainFooterRightColumn}>
              <div className="small mb-1 text-body-tertiary">{t('callUs')}</div>
              <CustomLink
                textDecoration={TextDecorationEnum.none}
                href={`tel:+1${retailerConfig.phoneNumber}`}
              >
                <ByPhoneOutline /> {formatPhoneNumberWithParens(retailerConfig.phoneNumber)}
              </CustomLink>
            </Col>
          )}
          {disclaimer && (
            <Row className="mt-4">
              <Col sm={16} lg={16}>
                {disclaimer}
              </Col>
            </Row>
          )}
        </Row>
      </Container>
    </footer>
  );
}
